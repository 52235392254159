import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    emailVerification: builder.mutation<any, EmailVerificationApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/userAuth`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };

export type EmailVerificationApiArg = {
  email?: string;
};

export const { useEmailVerificationMutation } = injectedRtkApi;
