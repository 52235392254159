import { useState } from "react";
import { useEmailVerificationMutation } from "../apis/studentVerificationApi";

const VerifyStudentModal = ({
  setPointer,
  setIsModal,
  setActiveIndex,
}: {
  setPointer: any;
  setIsModal: any;
  setActiveIndex: any;
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [verifyEmail] = useEmailVerificationMutation();
  const handleClick = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        setEmailError("");
        const lowerEmail = email.toLowerCase();
        const res = await verifyEmail({ email: lowerEmail }).unwrap();
        if (res.user.email) {
          setPointer(true);
          setIsModal(false);
          setActiveIndex(-1);
          window.localStorage.setItem("user", res.user.email);
        }
      } else {
        setEmailError("Enter a valid email address");
      }
    } catch (error: any) {
      handleNewEmail();
    }
  };
  const handleNewEmail = async () => {
    try {
      const newEmail =
        email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
      setEmailError("");
      const res = await verifyEmail({ email: newEmail }).unwrap();
      if (res.user.email) {
        setPointer(true);
        setIsModal(false);
        setActiveIndex(-1);
        window.localStorage.setItem("user", res.user.email);
      }
    } catch (error: any) {
      setEmailError(
        `AP Ranch registration not found.  Please register first and try again. <a href="https://student.apranch.org/" style="color: #164576;" target="_blank">Click Here</a>`
      );
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        height: " 100%",
        width: " 100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#0000007d",
        zIndex: 99999,
      }}
    >
      <div
        style={{
          background: "white",
          boxShadow: "0 0 4px 0px #000000",
          //  padding: "16px",
          maxWidth: "500px",
          borderRadius: 15,
          width: "90%",
        }}
      >
        <div
          className="d-flex"
          style={{
            alignItems: "center",
            borderBottom: "1px solid",
            marginBottom: 20,
            padding: "16px",
          }}
        >
          <h1
            style={{
              fontSize: "16px",
              fontWeight: 600,
              color: "black",
              fontFamily: "Satoshi",
              margin: 0,
            }}
          >
            Verify Registration
          </h1>
          <button
            className="ms-auto"
            onClick={() => setIsModal(false)}
            style={{
              fontWeight: 600,
              background: "transparent",
              height: "40px",
              width: "40px",
              border: "none",
              padding: 0,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width={15}
              height={15}
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
        <div
          style={{
            padding: "16px",
            color: "black",
            fontFamily: "Satoshi",
          }}
        >
          <input
            type="text"
            className="form-control "
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="text-end" style={{ padding: "0px 16px 16px" }}>
          {emailError && (
            <div
              style={{
                color: "red",
                fontSize: 14,
                fontWeight: 600,
                marginRight: 16,
                fontFamily: "Satoshi",
              }}
              dangerouslySetInnerHTML={{ __html: emailError }}
            />
          )}
          <button
            className="add-employee btn w-30 mt-3"
            onClick={() => handleClick()}
            style={{
              color: "white",
              fontFamily: "Satoshi",
              fontSize: "14px",
              fontWeight: 600,
              background: "#164576",
              marginRight: 10,
            }}
          >
            Submit
          </button>{" "}
          <button
            className="add-employee btn w-30 mt-3"
            onClick={() => setIsModal(false)}
            // onClick={() => setRejectionReasonModal(false)}
            style={{
              color: "white",
              fontFamily: "Satoshi",
              fontSize: "14px",
              fontWeight: 600,
              background: "#164576",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyStudentModal;
